<template>
  <div style="padding-bottom: 200px ">

    <div
      class="flex flex-wrap parent-div border-bottom-only pt-3"
      v-for="job in recurringJobs"
      :key="job.id"
    >

      <i
        class="las la-pen i-20 grey hover-show cursor-pointer"
        style="position:absolute;right:3rem"
      ></i>

      <div class="col-12 sm:col-6 field">
        <span class="font-light">Repeat Every: </span>
        {{ job.repeatIntervalLabel }}
      </div>

      <div class="col-12 sm:col-6 field">
        <span class="font-light
            ">Job Name: </span>
        {{ job.jobName }}
      </div>

      <div class="col-12 sm:col-6 field">
        <span class="font-light
            ">First Occurrence: </span>
        {{ job.firstOccurrence }}
      </div>

      <div class="col-12 sm:col-6 field">
        <span class="font-light
                ">Repeat Until: </span>
        {{ job.repeatUntil }}
      </div>

      <div class="field col-12 align-items-center mb-0">

        <div class="field-checkbox">
          <label
            for="notifyAE"
            style="color: #687a8d !important"
            class="ml-0"
          >Notify Account Executive</label>
          <Checkbox
            id="notifyAE"
            class="ml-2"
            v-model="job.notifyAccountExecutive"
            :binary="true"
          />
        </div>
      </div>

    </div>

  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      submitted: false,
      recurring: {
        repeatInterval: null,
        jobName: "",
        firstOccurrence: null,
        repeatUntil: null,
        notifyAccountExecutive: false,
      },

      recurringJobs: [],
    };
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      if (
        !this.recurring.repeatInterval ||
        !this.recurring.jobName ||
        !this.recurring.firstOccurrence ||
        !this.recurring.repeatUntil
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please complete all required fields.",
          life: 3000,
        });
        return;
      }
      // Submit logic here
      console.log("Recurring Data Submitted", this.recurring);
    },
    clearForm() {
      this.recurring = {
        repeatInterval: null,
        jobName: "",
        firstOccurrence: null,
        repeatUntil: null,
        notifyAccountExecutive: false,
      };
      this.submitted = false;
    },
  },
};
</script>
  
  <style>
</style>
  